<template>
    <aside class="navigation-wrapper" :class="[$org('slug'), {'is-open': open}]">
        <div class="rebranding-top-bar"></div>
        <section class="menu">
            <div class="logo">
                <img :src="$org('logos.pinkBackground')"/>
            </div>
            <component class="navigation" :is="isClient ? 'ClientNavigation' : 'AdminNavigation'">
                <template #mobile>
                    <router-link :to="{name: 'logout'}">
                        <span class="icon"><fa icon="sign-out-alt"/></span>
                        <p>{{ $t('navigation.logout') }}</p>
                    </router-link>
                </template>
            </component>
        </section>
        <footer class="hidden-lg hidden-md menu-footer" :class="{ 'iphone-x': iPhoneXDetected }">

            <template v-if="isClient">
                <router-link class="link-left" :to="{ name: 'frontend.overview' }">
                    <fa icon="chart-pie"/>
                    <p>{{$t('navigation.overview')}}</p>
                </router-link>

                <router-link class="link-right" v-if="!hasHealthyIntegration(['Google analytics'])" :to="{ name: 'frontend.reporting.seo' }">
                    <span class="icon"><fa icon="chart-line"/></span>
                    <p>{{$t('navigation.statistics.seo')}}</p>
                </router-link>

                <router-link class="link-right" v-else :to="{ name: 'frontend.reporting.visitors' }">
                    <span class="icon"><fa icon="chart-area"/></span>
                    <p>{{$t('navigation.statistics.visitors')}}</p>
                </router-link>
            </template>

            <template v-if="!isClient">
                <router-link class="link-left" :to="{name: 'backend.customers'}">
                    <span class="icon"><fa icon="users"/></span>
                    <p>{{$t('navigation.backend.customers')}}</p>
                </router-link>
            </template>

            <a class="link-toggle" v-if="!open" href="#" @click.prevent="toggleMenu(true)">
                <span class="icon"><fa icon="bars"/></span>
                <p>{{$t('navigation.mobile.more')}}</p>
            </a>
            <a class="link-toggle" v-else href="#" @click.prevent="toggleMenu(false)">
                <span class="icon"><fa icon="times"/></span>
                <p>{{$t('navigation.mobile.close')}}</p>
            </a>
        </footer>
    </aside>
</template>

<script>
  import { mapGetters } from 'vuex'
  import AdminNavigation from './components/navigation/AdminNavigation'
  import ClientNavigation from './components/navigation/ClientNavigation'
  import hasIntegration from '@/mixins/integrations/hasIntegration'

  export default {
    mixins: [hasIntegration],
    name: 'Navigation',

    data () {
      return {
        open: false
      }
    },

    watch: {
      $route(){
        this.toggleMenu(false);
      }
    },

    computed: {
      ...mapGetters('identity', ['isClient']),
      iPhoneXDetected () {
        const iPhone = /iPhone/.test(window.navigator.userAgent)
        const ratio = window.devicePixelRatio || 1

        const screen = {
          width: window.screen.width * ratio,
          height: window.screen.height * ratio
        }

        return iPhone && screen.width === 1125 && screen.height === 2436
      }
    },

    methods: {
      toggleMenu (value) {
        this.open = value;

        const body = document.getElementsByTagName('body')[0];

        if (this.open) {
          body.classList.add('menu-open');
        } else {
          body.classList.remove('menu-open');
        }
      }
    },

    components: {
      AdminNavigation,
      ClientNavigation
    }
  }
</script>

<style lang="scss">
    @import "~@/assets/scss/variables/all";

    body.menu-open {
        overflow: hidden;
    }

    .navigation-wrapper {
        .visible-on-mobile {
            display: none;
        }

        hr {
            height: 15px;
            margin: 0;
            background: none;
            border: 0;
        }

        .headline {
            font-size: 12px;
            padding: 5px 0 0 15px;
            margin: 0 0 10px;
            font-weight: 900;
            letter-spacing: 1px;
            color: #fff
        }

        p {
            margin: 0;
            font-size: 11px;
            font-weight: 600;
            text-transform: uppercase;
            white-space: nowrap;
            position: relative;
            letter-spacing: 1px;
        }

        &::before,
        .menu-footer::before {
            z-index: 0;
            content: "";
            position: absolute;
            display: block;
            width: 100%;
            height: 100vh;
            bottom: 0;
            left: 0;
        }

        .menu-footer {
            height: 60px;
            width: 100%;
            position: absolute;
            overflow: hidden;
            bottom: 0;
            z-index: 10;
            border-top: 1px solid rgba(0, 0, 0, 0);
            grid-template-areas: "left toggle right";
            grid-template-columns: 1fr 1fr 1fr;
            display: grid;

            .link-left {
                grid-area: left;
            }
            .link-right {
                grid-area: right;
            }
            .link-toggle {
                grid-area: toggle;
            }

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 60px;
                flex-direction: column;
                color: #fafafa;
                position: relative;
                font-size: 16px;
            }
        }

        &.is-open .menu-footer {
            border-top: 1px solid rgba(0, 0, 0, .15);
        }

        @media screen and (max-width: 991px) {
            z-index: 8000;
            position: fixed;
            width: 100%;
            height: 60px;
            left: 0;
            bottom: 0;
            overflow: hidden;
            transition: height 300ms ease-in-out;

            &.is-open {
                height: 100vh;
            }

            .menu {
                position: absolute;
                top: 0;
                width: 100%;
                z-index: 2;
                overflow: hidden;
                overflow-y: scroll;
                height: calc(100vh - 60px);
            }

            .headline {
                text-transform: uppercase;
            }

            .visible-on-mobile {
                display: grid;
            }


        }

        @media screen and (min-width: 992px) {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 260px;
            display: block;
            z-index: 1;
            color: #fff;

            &::before {
                position: fixed;
                width: 260px;
            }

            hr {
                height: 30px;
            }

            .headline {
                font-size: 20px;
                font-weight: 200;
            }
        }

        &.sgme,
        &.freemium,
        &.opn {
            .menu-footer::before,
            &::before {
                background-color: $power-pink;
                background-image: linear-gradient($power-pink, 60%, $power-pink-dark);
            }
        }

        &.spoton-marketing {
            .menu-footer::before,
            &::before {
                background-color: $power-pink;
                background-image: linear-gradient($power-pink, 60%, $power-pink-dark);
            }
        }

        .logo {
            width: 100%;
            justify-content: center;
            display: flex;
            position: relative;
            z-index: 2;
            padding: 25px 0;

            img {
                width: 80px;
            }
        }

        .navigation {
            position: relative;
            z-index: 2;

            h5{
                padding: 8px 30px;
            }

            a {
                padding: 8px 30px;
                display: flex;
                align-items: center;
                border-radius: 2px;
                color: #fff;
                opacity: .86;
                text-align: left;
                position: relative;

                &:hover,
                &.router-link-active {
                    background: rgba(255, 255, 255, 0.15);
                    color: #FFFFFF;
                    opacity: 1;
                }

                .icon {
                    margin-right: 5px;
                    font-size: 20px;
                    width: 30px;
                }

                .toggle-icon {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 10px;
                    margin: auto;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    svg {
                        transition: all 250ms ease-in-out;
                        transform: rotate(0deg);
                    }
                }

                &.toggled {
                    .toggle-icon svg {
                        transform: rotate(180deg);
                    }
                }
            }

            ul{
                background: darken($power-pink, 20%);
                li{
                    a{
                        padding:15px 65px;
                    }
                }

            }

            .beta {
                &:before {
                    content: "BETA";
                }

                display: inline-block;
                color: #ffffff;
                text-transform: uppercase;
                letter-spacing: 1px;
                font-size: 10px;
                padding-left: 2px;
                font-weight: 400;
                opacity: .8;
            }

            .plus {
                &:before {
                    content: "PLUS+";
                }

                display: inline-block;
                color: #ffffff;
                text-transform: uppercase;
                letter-spacing: 1px;
                font-size: 10px;
                padding-left: 2px;
                font-weight: 500;
                opacity: .8;
            }

            .toggle-nav {
                margin: 0;
                padding: 0;
                list-style: none;
                overflow: hidden;
                max-height: 0;
                transition: max-height ease-in-out 300ms;

                &.visible {
                    max-height: 300px;
                }
            }
        }
    }
</style>
