<template>
  <nav>
    <router-link :to="{name: 'backend.customers'}">
      <span class="icon">
        <fa icon="store" />
      </span>
      <p>{{$t('navigation.backend.customers')}}</p>
    </router-link>

	<router-link :to="{name: 'backend.leads'}">
      <span class="icon">
        <fa icon="user-plus" />
      </span>
	  <p>Add lead</p>
	</router-link>

    <router-link :to="{name: 'backend.users'}">
      <span class="icon">
        <fa icon="users" />
      </span>
      <p>{{$t('navigation.backend.users')}}</p>
    </router-link>

    <router-link :to="{name: 'backend.integration-check'}">
      <span class="icon">
        <fa icon="network-wired" />
      </span>
      <p>{{$t('navigation.backend.integrations')}}</p>
    </router-link>

    <router-link :to="{name: 'backend.cached-account-overview'}">
      <span class="icon">
        <fa icon="clock" />
      </span>
      <p>{{$t('navigation.backend.mcc')}}</p>
    </router-link>

    <router-link :to="{name: 'backend.stream'}">
      <span class="icon">
        <fa icon="share-alt" />
      </span>
      <p>{{$t('navigation.backend.stream')}}</p>
    </router-link>

    <!-- <router-link :to="{name: 'backend.abm'}">
      <span class="icon">
        <fa icon="crosshairs" />
      </span>
      <p>{{$t('navigation.abm')}}<sup class="beta"/></p>
    </router-link> -->

    <router-link :to="{name: 'backend.faq'}">
      <span class="icon">
        <fa icon="bookmark" />
      </span>
      <p>{{$t('navigation.backend.faq')}}</p>
    </router-link>

    <hr />

    <h5 class="headline">{{$t('navigation.admin')}}</h5>

    <router-link :to="{name: 'backend.performancereports'}">
      <span class="icon">
        <fa icon="clipboard-list" />
      </span>
      <p>{{$t('navigation.backend.performancereports')}}</p>
    </router-link>

    <router-link :to="{name: 'backend.adversus'}">
      <span class="icon">
        <fa icon="user-plus" />
      </span>
      <p>Adversus</p>
    </router-link>

    <router-link :to="{name: 'backend.serp-grid'}">
      <span class="icon">
        <fa icon="map-marker" />
      </span>
      <p>Serp Local Grid</p>
    </router-link>

    <router-link :to="{name: 'backend.subscriptions'}">
      <span class="icon">
        <fa icon="cog" />
      </span>
      <p>{{$t('navigation.backend.subscriptions')}}</p>
    </router-link>

    <router-link v-if="1 === 2" :to="{name: 'backend.system'}">
      <span class="icon">
        <fa icon="cogs" />
      </span>
      <p>{{$t('navigation.backend.system')}}</p>
    </router-link>

    <div class="visible-on-mobile">
      <hr />
      <!-- << PROFILE >> -->
      <h5 class="headline">{{$t('navigation.profileHeadline')}}</h5>

      <slot name="mobile" />
    </div>
  </nav>
</template>

<script>
export default {
  name: 'AdminNavigation'
}
</script>
